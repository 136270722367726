import {
  CourseModel,
  stringToSlug,
} from '@24hbs/utils';
import { getURLFromContentfulAsset, handleUrlClientSide } from '$lib/utils/assets-utils';
import { removeHTML, dateFullMonthFormat } from '$lib/utils/formatter';
import type { IInitiatives, INews } from './contentful/types';
import { PUBLIC_BASE_URL } from '$env/static/public';

type Event = IInitiatives;
type Article = INews;


const convertToISO8601 = (duration: number, durationType: string) => {
  const mapping: { [key: string]: string } = {
    'serate': 'D',
    'ore': 'H',
    'mesi': 'M',
    'weekend': 'D',  // fixed value 2
    'anni': 'Y',
    'giornate': 'D'
  };

  let iso8601Duration = 'P';

  if (durationType === 'weekend') {
    duration = 2;
  }

  const isoComponent = mapping[durationType];

  if (isoComponent === 'H' || isoComponent === 'M' || isoComponent === 'S') {  // Time components
    iso8601Duration += 'T';
  }

  iso8601Duration += `${duration}${isoComponent}`;

  return iso8601Duration;
}
export class StructureUtils {
  static getStructuredData(
    courses: CourseModel[],
    events: Event[],
    news: Article[]
  ) { }

  public static getArticleStructure(
    article: Article,
    withContext: boolean = false
  ) {
    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'NewsArticle',
      datePublished: article.fields.publishedDate,
      dateModified: article.sys.updatedAt,
      author: StructureUtils.getOrganizationStructure(),
      headline: article.fields.title.slice(0, 110),
      image: [
        getURLFromContentfulAsset(article.fields.thumbnailImage),
        getURLFromContentfulAsset(article.fields.heroImage),
      ],
    };
  }

  public static getCourseListStructure(
    courses: CourseModel[],
    withContext: boolean = false
  ) {
    const courseList = courses.map((course, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        url: StructureUtils.getCourseUrl(course, true),
      };
    });

    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'ItemList',
      itemListElement: courseList,
    };
  }

  public static getFaqStructure(
    list: { question: string; answer: string }[],
    withContext: boolean = false
  ) {
    const entryList = list.map((item) => {
      return {
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.answer,
        },
      };
    });

    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'FAQPage',
      mainEntity: entryList,
    };
  }

  public static getBreadcrumbStructure(
    pages: { name: string; url: string }[],
    withContext: boolean = false
  ) {
    const breadcrumbList = pages.map((page, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: page.name,
      item: page.url,
    }));

    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbList,
    };
  }

  public static getLogoStucture(withContext: boolean = false) {
    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'Organization',
      logo: `${PUBLIC_BASE_URL}favicon/logo-192.png`,
      url: PUBLIC_BASE_URL,
    };
  }

  public static getSearchBoxStructure(withContext: boolean = false) {
    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'WebSite',
      url: PUBLIC_BASE_URL,
      name: '24ORE Business School',
      description:
        '30 anni di eccellenza nella formazione: 500 Master in 12 settori, per trasformare passioni in successi professionali nel mondo del lavoro.',
      potentialAction: [
        {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: `${PUBLIC_BASE_URL}esplora/{search_term_string}`,
          },
          'query-input': 'required name=search_term_string',
        },
      ],
      inLanguage: 'it-IT',
    };
  }

  public static getCourseStructure(
    course: CourseModel,
    withContext: boolean = false
  ) {
    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'Course',
      name: course.category,
      description: StructureUtils.getCourseDescription(course),
      provider: StructureUtils.getOrganizationStructure(),
      // Are supported by google?
      image: handleUrlClientSide(course.previewUrl),
      ...(StructureUtils.getModulesStructures(course).length > 0 && { syllabusSections: StructureUtils.getModulesStructures(course) }),
      // mainEntityOfPage: StructureUtils.getCourseUrl(course, false),
      hasCourseInstance: StructureUtils.getCourseInstanceStructures(course),
      offers: StructureUtils.getOffersStructures(course)
    };
  }

  public static getCourseInstanceStructures(course: CourseModel) {
    const sessions = course.sessions;

    const courseInstances = [];

    if (sessions) {
      Object.keys(sessions).forEach((sessionId) => {
        const session = sessions[sessionId];

        const courseInstance = {
          '@type': 'CourseInstance',
          startDate: session.startDate,
          endDate: session.endDate,
          courseWorkload: convertToISO8601(session.duration, session.durationType),
          courseMode: session.location === "Online" ? "Online" : "Blended",
          location: "24 ORE Business School" + " " + session.location,
          instructor: session.instructors.map(instructor => ({
            "@type": "Person",
            "name": instructor.name + " " + instructor.surname
          }))
        };

        courseInstances.push(courseInstance);
      });
    }
    return courseInstances;
  }

  public static getModulesStructures(course: CourseModel) {
    const sessions = course.sessions;
    const modulesInstances = [];

    if (sessions) {
      Object.keys(sessions).forEach((sessionId) => {
        const session = sessions[sessionId];
        const mappedModules = session.modulesList.map(module => {
          return {
            '@type': 'Syllabus',
            'name': module
          };
        });
        modulesInstances.push(...mappedModules);
      });
    }
    return modulesInstances;
  }

  public static getOffersStructures(course: CourseModel) {
    const sessions = course.sessions;
    const offers = [];

    if (sessions) {
      Object.keys(sessions).forEach((sessionId) => {
        const session = sessions[sessionId];

        const offer = {
          '@type': 'Offer',
          priceCurrency: "EUR",
          price: session.discountPrice ? session.discountPrice : session.price ? session.price : 0,
          category: "Paid",
          availability: 'InStock',
          name: session.title + " " + session.location + " " + dateFullMonthFormat(session.startDate),
          sku: session.productCode
        };

        offers.push(offer);
      });
    }
    return offers;
  }

  public static createScriptTag(innerContent: string) {
    return `<script type="application/ld+json">${innerContent + '<'}/script>`;
  }

  public static getOrganizationStructure(withContext: boolean = false) {
    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'Organization',
      name: '24ORE Business School',
      sameAs: PUBLIC_BASE_URL,
    };
  }

  public static getAggregateStructure(name: string | null | undefined, image: string | null | undefined, withContext: boolean = false) {
    return {
      ...(withContext && { '@context': 'https://schema.org' }),
      '@type': 'Product',
      brand: {
        '@type': 'Brand',
        name: '24ORE Business School',
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        ratingCount: '91',
      },
      ...(name ? { name } : {}),
      ...(image ? { image } : {}),
    };
  }

  // ****************** PRIVATE HELPER METHODS *************************
  // *******************************************************************

  private static getCourseUrl(
    course: CourseModel,
    withSessionId: boolean = false
  ) {
    if (withSessionId) {
      return `${PUBLIC_BASE_URL}${stringToSlug(
        course.category
      )}/${course.slug}-${course.idMainSession}`;
    }
    return `${PUBLIC_BASE_URL}${stringToSlug(
      course.category
    )}/${course.slug}`;
  }

  private static getCourseDescription(course: CourseModel) {
    const whoyouwill = course.getMainSession().whoyouwill;
    const reasonwhy = course.getMainSession().reasonwhy;

    if (whoyouwill && removeHTML(whoyouwill).length > 0) {
      return removeHTML(whoyouwill);
    } else if (reasonwhy && removeHTML(reasonwhy).length > 0) {
      return removeHTML(reasonwhy);
    }

    return 'No description currently';
  }

  /**
   * Get the course name based on the course type and title
   * @param course The course model
   * @returns The course name
   */
  private static getCourseName(course: CourseModel) {
    // remove the course type from the title if it's already in the title
    // it is an hack due to the fact that the course type is added to the title
    if (course.title.includes(course.courseTypeFull)) {
      return course.title;
    }

    return `${course.courseTypeFull} ${course.title}`;
  }
}
